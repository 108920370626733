import { withStyles } from '@bruitt/classnames'

import { ColorProps } from '../../types/colors'
import { IconSize } from '../../types/icons'
import { Icon, IconName } from '../icon/icon'
import { StyledButton, StyledButtonProps } from '../styled-button'

import styles from './icon-simple-button.module.scss'

const sx = withStyles(styles)

interface IconSimpleButtonProps
  extends Omit<StyledButtonProps, 'variant' | 'color'>,
    ColorProps {
  variant?: StyledButtonProps['variant']
  icon: IconName
  s?: IconSize
}

export const IconSimpleButton = ({
  icon,
  s = '24',
  className,
  variant = 'ghost',
  ...rest
}: IconSimpleButtonProps) => {
  return (
    <StyledButton variant={variant} className={sx({ s }, className)} {...rest}>
      <Icon name={icon} s={s} />
    </StyledButton>
  )
}
