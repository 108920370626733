import { Controller, useFormContext } from 'react-hook-form'

import { Button } from '../../../../shared/uikit/button'
import { Flex } from '../../../../shared/uikit/flex'
import { Icon } from '../../../../shared/uikit/icon'
import { Select } from '../../../../shared/uikit/select'
import { RENT_AREA_TYPES_OPTIONS } from '../../constants'
import { RentAreaListItem, RentAreasFilterFormValues } from '../../types'

import s from './desktop-filter-form.module.scss'

interface DesktopFilterFormProps {
  projects: RentAreaListItem['project'][]
}

export const DesktopFilterForm = ({ projects }: DesktopFilterFormProps) => {
  const { control, reset } = useFormContext<RentAreasFilterFormValues>()

  return (
    <Flex mb="24" g="12">
      <Controller
        control={control}
        name="project"
        render={({ field }) => (
          <Select
            s="m"
            type="multi"
            valueName="id"
            labelName="name"
            placeholder="Проект"
            options={projects}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="type"
        render={({ field }) => (
          <Select
            s="m"
            type="multi"
            placeholder="Тип"
            options={RENT_AREA_TYPES_OPTIONS}
            {...field}
          />
        )}
      />
      <Button
        onClick={() => reset()}
        className={s.clear}
        variant="ghost"
        s="m"
        pre={<Icon name="close" color="primary-500" />}
      >
        Сбросить фильтр
      </Button>
    </Flex>
  )
}
