import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query'

import { getOrders, GetOrdersFilter } from '../../entities/order/api'
import { ORDER_LIST_QUERY_KEY } from '../../entities/order/constants'
import { OrderSortColumn } from '../../entities/order/types'
import { SortingRequestParams } from '../../shared/types/api-types'

import { ORDERS_COUNT_PER_PAGE } from './constants'

interface UseInfiniteOrdersParams {
  filter?: GetOrdersFilter
  sorting?: SortingRequestParams<OrderSortColumn>
}

export const useInfiniteOrders = ({
  filter = {},
  sorting,
}: UseInfiniteOrdersParams = {}) => {
  const { data, ...rest } = useInfiniteQuery({
    queryKey: [ORDER_LIST_QUERY_KEY, sorting, filter],
    queryFn: ({ pageParam }) =>
      getOrders({
        ...filter,
        sorting,
        pagination: {
          page: pageParam,
          countPerPage: ORDERS_COUNT_PER_PAGE,
        },
      }),
    getNextPageParam: (lastPage) =>
      lastPage.pagination?.hasMore
        ? lastPage.pagination.currentPage + 1
        : undefined,

    initialPageParam: 1,
    placeholderData: keepPreviousData,
  })

  return { pages: data?.pages || [], ...rest }
}
