import { OrdersFilterSearch } from '../../../../pages/orders-page/types'
import { Button } from '../../../../shared/uikit/button'
import { Flex } from '../../../../shared/uikit/flex'
import { InputDate } from '../../../../shared/uikit/input-date'
import { Select } from '../../../../shared/uikit/select'
import { Text } from '../../../../shared/uikit/text'
import { RentAreaListItem } from '../../../rent-area/types'
import {
  ORDER_STATUS_OPTIONS,
  ORDERS_FILTER_FORM_DEFAULT_VALUES,
} from '../../constants'
import {
  parseFilterFormValuesToFilterSearch,
  parseFilterSearchToFormValues,
} from '../../lib'
import { OrdersFilterFormValues, OrderType } from '../../types'

interface MobileFilterFormProps {
  filter: OrdersFilterSearch
  rentAreas: RentAreaListItem[]
  projects: RentAreaListItem['project'][]
  orderTypes: OrderType[]
  onChangeFilter: (filter: OrdersFilterSearch) => void
}

export const MobileFilterForm = ({
  filter,
  projects,
  rentAreas,
  orderTypes,
  onChangeFilter,
}: MobileFilterFormProps) => {
  const vs = parseFilterSearchToFormValues({
    filter,
    projects,
    rentAreas,
    orderTypes,
  })

  const handleChange = (values: OrdersFilterFormValues) =>
    onChangeFilter(parseFilterFormValuesToFilterSearch(values))

  return (
    <Flex dir="column" g="12" height="full" jc="space-between">
      <Flex dir="column" g="12">
        <Select
          type="multi"
          value={vs.statuses}
          onChange={(statuses) => handleChange({ statuses })}
          placeholder="Статус"
          options={ORDER_STATUS_OPTIONS}
        />
        <Select
          type="multi"
          value={vs.projects}
          onChange={(projects) => handleChange({ projects })}
          labelName="name"
          valueName="id"
          placeholder="Проект"
          options={projects}
        />
        <Select
          type="multi"
          value={vs.rentAreas}
          onChange={(rentAreas) => handleChange({ rentAreas })}
          labelName="unit"
          valueName="rentAreaId"
          options={rentAreas}
          placeholder="Помещения"
        />
        <Select
          type="multi"
          value={vs.orderTypes}
          onChange={(orderTypes) => handleChange({ orderTypes })}
          labelName="name"
          valueName="id"
          options={orderTypes}
          placeholder="Тип заявки"
        />

        <Text s="sub-headline-bold">Срок исполнения</Text>
        <InputDate
          value={vs.slaFrom}
          onChange={(slaFrom) => handleChange({ slaFrom })}
          placeholder="Дата начала"
        />
        <InputDate
          value={vs.slaTo}
          onChange={(slaTo) => handleChange({ slaTo })}
          placeholder="Дата окончания"
        />

        <Text s="sub-headline-bold">Дата создания</Text>
        <InputDate
          value={vs.createdFrom}
          onChange={(createdFrom) => handleChange({ createdFrom })}
          placeholder="Дата начала"
        />
        <InputDate
          value={vs.createdTo}
          onChange={(createdTo) => handleChange({ createdTo })}
          placeholder="Дата окончания"
        />
      </Flex>

      <Button
        variant="tertiary"
        s="l"
        onClick={() => handleChange(ORDERS_FILTER_FORM_DEFAULT_VALUES)}
      >
        Сбросить фильтр
      </Button>
    </Flex>
  )
}
