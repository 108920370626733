import { FileDetails } from '../../../../shared/types/api-types'
import { FilePreview } from '../../../../shared/uikit/file-preview'
import { useDeleteOrderFileMutation } from '../../hooks/use-delete-order-file-mutation'
import { useOrderFileQuery } from '../../hooks/use-order-file-query'

interface OrderFilePreviewProps {
  orderId: string
  fieldId: string
  file: FileDetails
  isReadOnly?: boolean
}

export const OrderFilePreview = ({
  orderId,
  fieldId,
  file,
  isReadOnly = false,
}: OrderFilePreviewProps) => {
  const { photoUrl, isError, refetch } = useOrderFileQuery({
    orderId,
    fieldId,
    fileName: file.fileName,
  })
  const { deleteOrderFile } = useDeleteOrderFileMutation({
    orderId,
    fieldId,
    fileName: file.fileName,
  })

  return photoUrl ? (
    <a href={photoUrl} target="_blank" rel="noreferrer">
      <FilePreview
        fileName={file.fileName}
        originalFileName={file.originalFileName}
        isError={isError}
        url={photoUrl}
        onDelete={isReadOnly ? undefined : deleteOrderFile}
        onRefetch={refetch}
      />
    </a>
  ) : (
    <FilePreview
      fileName={file.fileName}
      originalFileName={file.originalFileName}
      isError={isError}
      url={photoUrl}
      onDelete={isReadOnly ? undefined : deleteOrderFile}
      onRefetch={refetch}
    />
  )
}
