import { useMutation, useQueryClient } from '@tanstack/react-query'

import { changeOrderFields } from '../api'
import { ORDER_LIST_QUERY_KEY, ORDER_QUERY_KEY } from '../constants'
import { parseOrderFormValuesToUpdateRequest } from '../lib'
import {
  CustomFieldsFilesValues,
  CustomFieldsFormValues,
} from '../types-schema'

interface UseChangeOrderFieldsMutationParams {
  orderId: string
  onSuccess?: () => void
}

export const useChangeOrderFieldsMutation = ({
  orderId,
  onSuccess,
}: UseChangeOrderFieldsMutationParams) => {
  const qc = useQueryClient()
  const { isPending, mutate } = useMutation({
    mutationFn: changeOrderFields,
    onSuccess: () => {
      void qc.refetchQueries({
        queryKey: [ORDER_LIST_QUERY_KEY],
      })
      void qc.invalidateQueries({
        queryKey: [ORDER_QUERY_KEY, orderId],
      })
      onSuccess?.()
    },
  })

  const onSubmit = (
    fieldsValues: CustomFieldsFormValues,
    filesValues: CustomFieldsFilesValues,
  ) => {
    const data = parseOrderFormValuesToUpdateRequest(
      orderId,
      fieldsValues,
      filesValues,
    )

    mutate(data)
  }

  return {
    isPending: isPending,
    onSubmit,
  }
}
