import { Link } from '@tanstack/react-router'
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { OrderCard } from '../../../../entities/order/components/order-card'
import { OrderHead } from '../../../../entities/order/components/order-head'
import { OrderRow } from '../../../../entities/order/components/order-row'
import { parseFilterSearchToRequestDto } from '../../../../entities/order/lib'
import { OrderSortColumn } from '../../../../entities/order/types'
import { OrdersFilterSearch } from '../../../../pages/orders-page/types'
import { EmptyList } from '../../../../shared/components/empty-list'
import { ROUTES } from '../../../../shared/constants/routes'
import { SortingRequestParams } from '../../../../shared/types/api-types'
import { SortDirection } from '../../../../shared/types/sort'
import { Box } from '../../../../shared/uikit/box'
import { Flex } from '../../../../shared/uikit/flex'
import { Loader } from '../../../../shared/uikit/loader'
import { Table } from '../../../../shared/uikit/table'
import { ORDERS_COUNT_PER_PAGE } from '../../constants'
import { useInfiniteOrders } from '../../use-infinite-orders'

const FAKE_LIST = new Array(ORDERS_COUNT_PER_PAGE).fill(null)

interface OrderListProps {
  filter?: OrdersFilterSearch
  sorting: OrderSortColumn
  sortingDir: SortDirection
  onChangeSort: (s: SortingRequestParams<OrderSortColumn>) => void
}

export const OrderList = ({
  filter,
  sorting,
  sortingDir,
  onChangeSort,
}: OrderListProps) => {
  const { pages, fetchNextPage, isLoading, isFetched } = useInfiniteOrders({
    filter: filter ? parseFilterSearchToRequestDto(filter) : undefined,
    sorting: { columnName: sorting, direction: sortingDir },
  })

  const { ref, inView } = useInView({
    rootMargin: isFetched ? '500px' : '200px',
  })

  useEffect(() => {
    if (inView && isFetched) {
      void fetchNextPage()
    }
  }, [inView, fetchNextPage, isFetched])

  if (isFetched && pages?.[0]?.list?.length === 0) {
    return (
      <Box mt="32">
        <EmptyList title="Нет заявок" imgSrc="/empty-state-email.png" />
      </Box>
    )
  }

  return (
    <div>
      <Flex g="8" dir="column" hidden_md>
        {pages.map((page) =>
          page.list?.map((order) => (
            <Link
              key={order.id}
              to={ROUTES.orderId}
              params={{ orderId: order.id }}
            >
              <OrderCard order={order} />
            </Link>
          )),
        )}
      </Flex>

      <Box hidden display_md="block">
        <Table>
          <OrderHead
            sorting={sorting}
            sortingDir={sortingDir}
            onChangeSort={onChangeSort}
          />

          {isLoading &&
            pages.length === 0 &&
            FAKE_LIST.map((_, i) => (
              <React.Fragment key={i}>
                <OrderRow.Skeleton />
                <Table.Divider />
              </React.Fragment>
            ))}

          {pages.map((page) =>
            page.list?.map((order) => (
              <React.Fragment key={order.id}>
                <Link to={ROUTES.orderId} params={{ orderId: order.id }}>
                  <OrderRow order={order} />
                </Link>
                <Table.Divider />
              </React.Fragment>
            )),
          )}
        </Table>
      </Box>

      {pages.at(-1)?.pagination?.hasMore && (
        <Flex width="full" jc="center" mt="16">
          <Loader.InBox color="primary-500" />
        </Flex>
      )}

      {pages.length > 0 && <div ref={ref} />}
    </div>
  )
}
