import { getRouteApi } from '@tanstack/react-router'
import { useEffect } from 'react'

import { ROUTES } from '../../shared/constants/routes'

const { useNavigate } = getRouteApi('/_layout-main/')

export const IndexPage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    void navigate({ to: ROUTES.rentAreas })
  }, [navigate])

  return null

  // return (
  //   <Box px="20" px_md="0" pt="20" pt_md="0">
  //     <Flex jc="space-between">
  //       <PageHeader>Дашборд</PageHeader>
  //       <Box hidden display_lg="block">
  //         <ProfileBar />
  //       </Box>
  //     </Flex>
  //     <Box mt="24">
  //       {/*  */}
  //       {/*  */}
  //     </Box>
  //   </Box>
  // )
}
