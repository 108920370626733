import { serialize } from 'object-to-formdata'

import { bffRequest } from '../../shared/api/bff-request'
import { JSON_TO_FORM_DATA_OPTIONS } from '../../shared/constants/files'
import {
  ListResponse,
  PaginationRequestParams,
  SortingRequestParams,
} from '../../shared/types/api-types'

import { Order, OrderListItem, OrderSortColumn, OrderType } from './types'

export const getOrder = (id: string) =>
  bffRequest.get<Order>(`/Orders/GetOrder?id=${id}`)

export interface GetOrdersFilter {
  number?: string
  typeIds?: number[]
  subtypeIds?: number[]
  statuses?: string[]
  initiatorId?: string
  executorId?: string
  rentAreaIds?: string[]
  projectIds?: string[]
  personClientIds?: string[]
  executorIds?: string[]
  plannedDateFilter?: {
    start?: string
    end?: string
  }
  createdDateFilter?: {
    start?: string
    end?: string
  }
  isCompleted?: boolean
}

interface GetOrdersRequest extends GetOrdersFilter {
  pagination: PaginationRequestParams
  sorting?: SortingRequestParams<OrderSortColumn>
}

export const getOrders = (body: GetOrdersRequest) =>
  bffRequest.post<ListResponse<OrderListItem>>('/Orders/Search', body)

export interface AddOrderFilesRequest {
  OrderId: string
  FieldId: string
  Files: File[]
}

export const addOrderFiles = (data: AddOrderFilesRequest) =>
  bffRequest.post('/Orders/AddOrderFiles', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })

export interface GetDeleteOrderFileParams {
  orderId: string
  fieldId: string
  fileName: string
}

export const getOrderFile = ({
  orderId,
  fieldId,
  fileName,
}: GetDeleteOrderFileParams) =>
  bffRequest
    .get<Blob>(
      `/Orders/GetOrderFile?orderId=${orderId}&fieldId=${fieldId}&fileName=${fileName}`,
      { responseType: 'blob' },
    )
    .then((blob) => URL.createObjectURL(blob))

export const deleteOrderFile = ({
  orderId,
  fieldId,
  fileName,
}: GetDeleteOrderFileParams) =>
  bffRequest.delete(
    `/Orders/DeleteOrderFile?orderId=${orderId}&fieldId=${fieldId}&fileName=${fileName}`,
  )

export interface ChangeOrderStatusRequest {
  orderId: string
  status: string
}

interface ChangeOrderStatusResponse {
  otpConfirmationRequired: boolean
}

export const changeOrderStatus = (body: ChangeOrderStatusRequest) =>
  bffRequest
    .post<ChangeOrderStatusResponse>('/Orders/ChangeOrderStatus', body)
    .then((response) => ({
      ...response,
      ...body,
    }))

export interface ConfirmChangeOrderStatusRequest {
  orderId: string
  status: string
  otpCode: string
}

export interface OrderFieldsDto {
  fieldId: number
  values: string[]
}

export interface OrderFilesDto {
  fieldId: number
  files: File[]
}

export const confirmChangeOrderStatus = (
  body: ConfirmChangeOrderStatusRequest,
) => bffRequest.post('/Orders/ConfirmOrderStatusChange', body)

export interface ChangeOrderFieldsRequest {
  orderId: string
  fields: OrderFieldsDto[]
  fileFields: OrderFilesDto[]
}

export const changeOrderFields = (body: ChangeOrderFieldsRequest) => {
  const formData = serialize(body, JSON_TO_FORM_DATA_OPTIONS)
  return bffRequest.post('/Orders/ChangeOrderFields', formData)
}

export const getOrderTypes = () =>
  bffRequest.get<{ types: OrderType[] }>('/Orders/GetOrderTypes')
