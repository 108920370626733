import { getRouteApi, useRouter } from '@tanstack/react-router'
import { useMemo } from 'react'

import { useOrderQuery } from '../../entities/order/hooks/use-order-query'
import { parseOrderSchema } from '../../entities/order/lib'
import { OrderStatus } from '../../entities/order/types'

const { useParams } = getRouteApi('/_layout-main/orders/$orderId')

export const useOrderPage = () => {
  const { orderId } = useParams()
  const { order: o } = useOrderQuery(orderId)
  const router = useRouter()

  const goBack = () => router.history.back()

  const formSchema = useMemo(
    () =>
      parseOrderSchema({
        fieldsSchema: o?.subtype.fieldsSchema,
        statusesSchema: o?.subtype.statusesSchema,
      }),
    [o],
  )

  const hasSla = formSchema.plannedCompletionMinutes !== undefined
  const executor = o?.executor
    ? {
        id: o?.executor.id,
        fullName: o?.executor.fullName,
      }
    : null

  const statusOptions: OrderStatus[] =
    formSchema.transitions?.map(({ statusCode, actionTitle }) => ({
      code: statusCode,
      title: actionTitle,
    })) || []

  return {
    flags: {
      hasSla,
    },
    data: {
      orderId,
      order: o,
      status: o?.status || null,
      executor,
      statusOptions,
      formSchema,
    },
    actions: {
      goBack,
    },
  }
}
