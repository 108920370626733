import { Controller, useFormContext } from 'react-hook-form'

import { Button } from '../../../../shared/uikit/button'
import { Flex } from '../../../../shared/uikit/flex'
import { Select } from '../../../../shared/uikit/select'
import { RENT_AREA_TYPES_OPTIONS } from '../../constants'
import { RentAreaListItem, RentAreasFilterFormValues } from '../../types'

interface MobileFilterFormProps {
  projects: RentAreaListItem['project'][]
}

export const MobileFilterForm = ({ projects }: MobileFilterFormProps) => {
  const { control, reset } = useFormContext<RentAreasFilterFormValues>()

  return (
    <Flex dir="column" g="12" height="full" jc="space-between">
      <Flex dir="column" g="12">
        <Controller
          control={control}
          name="project"
          render={({ field }) => (
            <Select
              type="single"
              valueName="id"
              labelName="name"
              placeholder="Проект"
              options={projects}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <Select
              type="single"
              placeholder="Тип"
              options={RENT_AREA_TYPES_OPTIONS}
              {...field}
            />
          )}
        />
      </Flex>

      <Button variant="tertiary" s="l" onClick={() => reset()}>
        Сбросить фильтр
      </Button>
    </Flex>
  )
}
