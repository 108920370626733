import { useMutation, useQueryClient } from '@tanstack/react-query'

import { deleteOrderFile, GetDeleteOrderFileParams } from '../api'
import { ORDER_QUERY_KEY } from '../constants'

export const useDeleteOrderFileMutation = ({
  orderId,
  fieldId,
  fileName,
}: GetDeleteOrderFileParams) => {
  const qc = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      deleteOrderFile({
        orderId,
        fieldId,
        fileName,
      }),
    onSuccess: () => {
      void qc.invalidateQueries({ queryKey: [ORDER_QUERY_KEY, orderId] })
    },
  })

  return {
    isPending,
    deleteOrderFile: mutate,
  }
}
