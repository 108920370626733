import Add from './icons/add.svg?react'
import Alert from './icons/alert.svg?react'
import Archive from './icons/archive.svg?react'
import ArrowDownS from './icons/arrow-down-s.svg?react'
import ArrowLeftS from './icons/arrow-left-s.svg?react'
import ArrowLeft from './icons/arrow-left.svg?react'
import ArrowRightS from './icons/arrow-right-s.svg?react'
import ArrowUpS from './icons/arrow-up-s.svg?react'
import Attachment2 from './icons/attachment-2.svg?react'
import BarChartHorizontalFilled from './icons/bar-chart-horizontal-filled.svg?react'
import Calendar from './icons/calendar.svg?react'
import Camera from './icons/camera.svg?react'
import Chat from './icons/chat.svg?react'
import Check from './icons/check.svg?react'
import CheckboxCircleFilled from './icons/checkbox-circle-filled.svg?react'
import Close from './icons/close.svg?react'
import Counter from './icons/counter.svg?react'
import Crop from './icons/crop.svg?react'
import DeleteBin6 from './icons/delete-bin-6.svg?react'
import Discuss from './icons/discuss.svg?react'
import Download4 from './icons/download-4.svg?react'
import Equalizer from './icons/equalizer.svg?react'
import ExchangeDollar from './icons/exchange-dollar.svg?react'
import EyeClose from './icons/eye-close.svg?react'
import Home6 from './icons/home-6.svg?react'
import Home from './icons/home.svg?react'
import HotelBedFilled from './icons/hotel-bed-filled.svg?react'
import ImageAdd from './icons/image-add.svg?react'
import IndeterminateCircleFilled from './icons/indeterminate-circle-filled.svg?react'
import InformationFilled from './icons/information-filled.svg?react'
import Information from './icons/information.svg?react'
import Layout2Filled from './icons/layout-2-filled.svg?react'
import Layout4 from './icons/layout-4.svg?react'
import LayoutColumn from './icons/layout-column.svg?react'
import Loader2 from './icons/loader-2.svg?react'
import Loader4 from './icons/loader-4.svg?react'
import LogoutBoxR from './icons/logout-box-r.svg?react'
import MailSend from './icons/mail-send.svg?react'
import Menu from './icons/menu.svg?react'
import Message2 from './icons/message-2.svg?react'
import Message3 from './icons/message-3.svg?react'
import Mic from './icons/mic.svg?react'
import Notification3Filled from './icons/notification-3-filled.svg?react'
import Notification3 from './icons/notification-3.svg?react'
import Pencil from './icons/pencil.svg?react'
import PhoneFilled from './icons/phone-filled.svg?react'
import Phone from './icons/phone.svg?react'
import PineFilled from './icons/pine-filled.svg?react'
import PushpinFilled from './icons/pushpin-filled.svg?react'
import QuestionAnswerFilled from './icons/question-answer-filled.svg?react'
import QuestionFilled from './icons/question-filled.svg?react'
import Refresh from './icons/refresh.svg?react'
import Restaurant2Filled from './icons/restaurant-2-filled.svg?react'
import RoundedCorner from './icons/rounded-corner.svg?react'
import Search from './icons/search.svg?react'
import Settings4Filled from './icons/settings-4-filled.svg?react'
import Settings4 from './icons/settings-4.svg?react'
import ShareBox from './icons/share-box.svg?react'
import ShoppingCart2Filled from './icons/shopping-cart-2-filled.svg?react'
import Spam2Filled from './icons/spam-2-filled.svg?react'
import Spam2 from './icons/spam-2.svg?react'
import Team from './icons/team.svg?react'
import Telegram2Filled from './icons/telegram-2-filled.svg?react'
import Telegram2 from './icons/telegram-2.svg?react'
import Time from './icons/time.svg?react'
import WhatsappFilled from './icons/whatsapp-filled.svg?react'
import Whatsapp from './icons/whatsapp.svg?react'

export const ICON_TYPE_MAP = {
  add: Add,
  alert: Alert,
  archive: Archive,
  'arrow-down-s': ArrowDownS,
  'arrow-left-s': ArrowLeftS,
  'arrow-left': ArrowLeft,
  'arrow-right-s': ArrowRightS,
  'arrow-up-s': ArrowUpS,
  'attachment-2': Attachment2,
  'bar-chart-horizontal-filled': BarChartHorizontalFilled,
  calendar: Calendar,
  camera: Camera,
  chat: Chat,
  check: Check,
  'checkbox-circle-filled': CheckboxCircleFilled,
  close: Close,
  counter: Counter,
  crop: Crop,
  'delete-bin-6': DeleteBin6,
  discuss: Discuss,
  'download-4': Download4,
  equalizer: Equalizer,
  'exchange-dollar': ExchangeDollar,
  'eye-close': EyeClose,
  'home-6': Home6,
  home: Home,
  'hotel-bed-filled': HotelBedFilled,
  'image-add': ImageAdd,
  'indeterminate-circle-filled': IndeterminateCircleFilled,
  'information-filled': InformationFilled,
  information: Information,
  'layout-2-filled': Layout2Filled,
  'layout-4': Layout4,
  'layout-column': LayoutColumn,
  'loader-2': Loader2,
  'loader-4': Loader4,
  'logout-box-r': LogoutBoxR,
  'mail-send': MailSend,
  menu: Menu,
  'message-2': Message2,
  'message-3': Message3,
  mic: Mic,
  'notification-3-filled': Notification3Filled,
  'notification-3': Notification3,
  pencil: Pencil,
  'phone-filled': PhoneFilled,
  phone: Phone,
  'pine-filled': PineFilled,
  'pushpin-filled': PushpinFilled,
  'question-answer-filled': QuestionAnswerFilled,
  'question-filled': QuestionFilled,
  refresh: Refresh,
  'restaurant-2-filled': Restaurant2Filled,
  'rounded-corner': RoundedCorner,
  search: Search,
  'settings-4-filled': Settings4Filled,
  'settings-4': Settings4,
  'share-box': ShareBox,
  'shopping-cart-2-filled': ShoppingCart2Filled,
  'spam-2-filled': Spam2Filled,
  'spam-2': Spam2,
  team: Team,
  'telegram-2-filled': Telegram2Filled,
  'telegram-2': Telegram2,
  time: Time,
  'whatsapp-filled': WhatsappFilled,
  whatsapp: Whatsapp,
}
