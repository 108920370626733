import { z } from 'zod'

import { ORDER_LIST_SORT_FIELDS } from '../../entities/order/constants'
import { zodEnum } from '../../shared/lib/zod-enum'
import { SORT_DIRECTIONS } from '../../shared/types/sort'

export const orderFilterSearchSchema = z.object({
  statuses: z.array(z.string()).optional(),
  projects: z.array(z.string()).optional(),
  rentAreas: z.array(z.string()).optional(),
  orderTypes: z.array(z.string()).optional(),
  slaFrom: z.string().nullable().optional(),
  slaTo: z.string().nullable().optional(),
  createdFrom: z.string().nullable().optional(),
  createdTo: z.string().nullable().optional(),
})

export const ordersSearchParamsSchema = z
  .object({
    sorting: z
      .enum(zodEnum(Object.values(ORDER_LIST_SORT_FIELDS)))
      .optional()
      .catch(undefined),
    sortingDir: z
      .enum(zodEnum(Object.values(SORT_DIRECTIONS)))
      .optional()
      .catch(undefined),
  })
  .merge(orderFilterSearchSchema)

export type OrdersSearch = z.infer<typeof ordersSearchParamsSchema>
export type OrdersFilterSearch = z.infer<typeof orderFilterSearchSchema>
