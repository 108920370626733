import { useQuery } from '@tanstack/react-query'

import { getOrderTypes } from '../api'
import { ORDER_TYPES_QUERY_KEY } from '../constants'

export const useOrderTypesQuery = () => {
  const { data, ...rest } = useQuery({
    queryKey: [ORDER_TYPES_QUERY_KEY],
    queryFn: getOrderTypes,
  })

  return { orderTypes: data?.types || [], ...rest }
}
