import { useEffect, useState } from 'react'

import { CustomFieldsForm } from '../../entities/order/components/custom-fields-form'
import { useCustomFieldsForm } from '../../entities/order/components/custom-fields-form/use-custom-fields-form'
import { OrderSlaTag } from '../../entities/order/components/order-sla-tag'
import { OrderStatusConfirmModal } from '../../entities/order/components/order-status-confirm-modal'
import { OrderStatusTag } from '../../entities/order/components/order-status-tag'
import {
  ORDER_ORIGINS,
  ORDER_SMS_RETRY_TIMEOUT_SEC,
} from '../../entities/order/constants'
import { useChangeOrderFieldsMutation } from '../../entities/order/hooks/use-change-order-fields-mutation'
import { useChangeOrderStatus } from '../../entities/order/hooks/use-change-order-status'
import { OrderStatus } from '../../entities/order/types'
import { useProfileQuery } from '../../entities/user/hooks/use-profile-query'
import { PageHeader } from '../../shared/components/page-header'
import { NBSP } from '../../shared/constants/nbsp'
import { parseDate } from '../../shared/lib/dates'
import { Box } from '../../shared/uikit/box'
import { Button } from '../../shared/uikit/button'
import { Card } from '../../shared/uikit/card'
import { FeatureValue } from '../../shared/uikit/feature-value'
import { Flex } from '../../shared/uikit/flex'
import { Grid } from '../../shared/uikit/grid'
import { IconSimpleButton } from '../../shared/uikit/icon-simple-button'
import { Select } from '../../shared/uikit/select'
import { SkeletonBox } from '../../shared/uikit/skeleton-box'
import { Text } from '../../shared/uikit/text'
import { ProfileBar } from '../../widgets/profile-bar/components/profile-bar'

import { useOrderPage } from './use-order-page'

import s from './order-page.module.scss'

export const OrderPage = () => {
  const {
    data: { orderId, order: o, formSchema },
    actions: { goBack },
  } = useOrderPage()

  const {
    isDirty: isDetailsFormDirty,
    values: detailsValues,
    files: detailsFiles,
    remoteFiles: detailsRemoteFiles,
    handleChangeValue: handleChangeDetailsValue,
    handleChangeFiles: handleChangeDetailsFiles,
    errors: detailsErrors,
    handleSubmit: handleDetailsFormSubmit,
    handleReset: handleDetailsFormReset,
    onSuccess: onSuccessDetails,
  } = useCustomFieldsForm({
    initialValues: o?.fields,
    initialFiles: o?.fileFields,
    fields: formSchema.fields,
    permissions: formSchema.permissions,
    onSubmit: (values, files) => {
      onSubmitDetails(values, files)
    },
  })

  const { isPending: isPendingChangeDetailsFields, onSubmit: onSubmitDetails } =
    useChangeOrderFieldsMutation({
      orderId,
      onSuccess: onSuccessDetails,
    })

  const {
    isDirty: isProcessFormDirty,
    values: processValues,
    files: processFiles,
    remoteFiles: processRemoteFiles,
    handleChangeValue: handleChangeProcessValue,
    handleChangeFiles: handleChangeProcessFiles,
    errors: processErrors,
    handleSubmit: handleProcessFormSubmit,
    handleReset: handleProcessFormReset,
    onSuccess: onSuccessProcess,
  } = useCustomFieldsForm({
    initialValues: o?.fields,
    initialFiles: o?.fileFields,
    fields: formSchema.fields,
    permissions: formSchema.permissions,
    onSubmit: (values, files) => {
      onSubmitProcess(values, files)
    },
  })

  const { isPending: isPendingChangeProcessFields, onSubmit: onSubmitProcess } =
    useChangeOrderFieldsMutation({
      orderId,
      onSuccess: onSuccessProcess,
    })

  return (
    <>
      <Box px="20" px_md="0" pt="20" pt_md="0">
        <Flex jc="space-between" mb="20" mb_md="32">
          <Flex g="16" ai="center">
            <IconSimpleButton
              icon="arrow-left"
              s="20"
              variant="ghost"
              onClick={goBack}
            />
            <PageHeader>
              {o ? (
                <>
                  Заявка №{o?.number.toString().padStart(7, '0')},{' '}
                  {parseDate(o?.createdDate, 'dd.MM.yyyy в HH:mm')}
                </>
              ) : (
                'Заявка'
              )}
            </PageHeader>
          </Flex>
          <Box hidden display_lg="block">
            <ProfileBar />
          </Box>
        </Flex>
      </Box>

      <Grid className={s.grid}>
        <Box>
          <Card px="20" pt="20" px_lg="24" pt_lg="24" pb="48">
            <Text s="title3" s_lg="title2">
              Детали заявки
            </Text>
            {o ? (
              <Box mt="24">
                <CustomFieldsForm
                  orderId={orderId}
                  fields={formSchema.fields}
                  layout={formSchema.layoutDetails}
                  permissions={formSchema.permissions}
                  values={detailsValues}
                  files={detailsFiles}
                  remoteFiles={detailsRemoteFiles}
                  errors={detailsErrors}
                  onChangeFiles={handleChangeDetailsFiles}
                  onChangeValue={handleChangeDetailsValue}
                />

                <Flex mt="24" g="12">
                  <Button
                    isLoading={isPendingChangeDetailsFields}
                    disabled={!isDetailsFormDirty}
                    s="m"
                    variant="primary"
                    onClick={handleDetailsFormSubmit}
                  >
                    Сохранить
                  </Button>
                  <Button
                    s="m"
                    variant="secondary"
                    onClick={handleDetailsFormReset}
                    disabled={!isDetailsFormDirty}
                  >
                    Отменить
                  </Button>
                </Flex>
              </Box>
            ) : (
              <>
                <Grid g="12" cols="1" cols_md="2" mt="24">
                  <SkeletonBox h="56" />
                  <SkeletonBox h="56" />
                  <SkeletonBox h="56" />
                  <SkeletonBox h="56" />
                  <SkeletonBox h="56" />
                  <SkeletonBox h="56" />
                </Grid>
                <Grid g="12" mt="32">
                  <SkeletonBox h="124" />
                  <SkeletonBox h="124" />
                </Grid>
              </>
            )}

            {formSchema.layoutProcess.length > 0 && (
              <Box hidden_md>
                <Card.Divider my="20" hidden_md />

                <Text s="title3" s_lg="title2">
                  Исполнение заявки
                </Text>
                <Box mt="24">
                  <CustomFieldsForm
                    orderId={orderId}
                    fields={formSchema.fields}
                    layout={formSchema.layoutProcess}
                    permissions={formSchema.permissions}
                    values={processValues}
                    files={processFiles}
                    remoteFiles={processRemoteFiles}
                    onChangeValue={handleChangeProcessValue}
                    onChangeFiles={handleChangeProcessFiles}
                    errors={processErrors}
                  />

                  <Flex mt="24" g="12">
                    <Button
                      isLoading={isPendingChangeProcessFields}
                      disabled={!isProcessFormDirty}
                      s="m"
                      variant="primary"
                      onClick={handleProcessFormSubmit}
                    >
                      Сохранить
                    </Button>
                    <Button
                      s="m"
                      variant="secondary"
                      onClick={handleProcessFormReset}
                      disabled={!isProcessFormDirty}
                    >
                      Отменить
                    </Button>
                  </Flex>
                </Box>
              </Box>
            )}

            <Card.Divider my="20" hidden_md />

            <Box hidden_md>
              <Management />
            </Box>
          </Card>

          {formSchema.layoutProcess.length > 0 && (
            <Card
              px="20"
              pt="20"
              px_lg="24"
              pt_lg="24"
              pb="48"
              mt="16"
              hidden
              display_md="block"
            >
              <Text s="title3" s_lg="title2">
                Исполнение заявки
              </Text>
              <Box mt="24">
                <CustomFieldsForm
                  orderId={orderId}
                  fields={formSchema.fields}
                  layout={formSchema.layoutProcess}
                  permissions={formSchema.permissions}
                  values={processValues}
                  files={processFiles}
                  onChangeValue={handleChangeProcessValue}
                  onChangeFiles={handleChangeProcessFiles}
                  errors={processErrors}
                />

                <Flex mt="24" g="12">
                  <Button
                    isLoading={isPendingChangeProcessFields}
                    disabled={!isProcessFormDirty}
                    s="m"
                    variant="primary"
                    onClick={handleProcessFormSubmit}
                  >
                    Сохранить
                  </Button>
                  <Button
                    s="m"
                    variant="secondary"
                    onClick={handleProcessFormReset}
                    disabled={!isProcessFormDirty}
                  >
                    Отменить
                  </Button>
                </Flex>
              </Box>
            </Card>
          )}
        </Box>

        <Card hidden display_md="block" p="24">
          <Management />
        </Card>
      </Grid>
    </>
  )
}

const Management = () => {
  const {
    flags: { hasSla },
    data: { orderId, order: o, status, executor, statusOptions },
  } = useOrderPage()

  const { profile } = useProfileQuery()

  const [counter, setCounter] = useState(0)

  const {
    code,
    setCode,
    newStatus,
    confirmModal,
    onModalClose,
    isPendingChangeStatus,
    isPendingConfirmStatus,
    changeOrderStatus,
    confirmChangeOrderStatus,
  } = useChangeOrderStatus(orderId)

  const handleChangeStatus = (status: OrderStatus | null) => {
    if (!status) return
    changeOrderStatus(status.code)
    setCounter(ORDER_SMS_RETRY_TIMEOUT_SEC)
  }

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000)
    }
  }, [counter])

  return (
    <>
      <Grid g="16" ji="start">
        <Text s="sub-headline-bold" color="text-500">
          Управление заявкой
        </Text>

        {o && statusOptions.length > 0 ? (
          <Select
            type="single"
            placeholder="Статус"
            valueName="code"
            labelName="title"
            value={status}
            options={statusOptions}
            onChange={(status) => handleChangeStatus(status)}
            isLoading={isPendingChangeStatus}
          />
        ) : status ? (
          <OrderStatusTag status={status} />
        ) : null}

        <FeatureValue title="Исполнитель">
          {executor?.fullName || '-'}
        </FeatureValue>

        <FeatureValue title="Плановый срок">
          <div>
            {parseDate(o?.plannedCompletionDate, 'dd.MM.yyyy в HH:mm') || NBSP}
          </div>

          {hasSla && (
            <Box mt="4">
              <OrderSlaTag
                deadlineNotificationDate={o?.deadlineNotificationDate}
                plannedCompletionDate={o?.plannedCompletionDate}
                statusChangePlannedDate={o?.statusChangePlannedDate}
                statusDeadlineNotificationDate={
                  o?.statusDeadlineNotificationDate
                }
              />
            </Box>
          )}
        </FeatureValue>

        <Card bgColor="base-50" width="full">
          <Card.Body>
            <Grid rg="16">
              <FeatureValue title="Проект">
                {o?.rentArea?.projectName || '-'}
              </FeatureValue>
              <FeatureValue title="Помещение">
                {o?.rentArea?.unit || o?.rentArea?.name || '-'}
              </FeatureValue>
            </Grid>
          </Card.Body>
        </Card>

        <FeatureValue title="Тип">{o?.type.name || '-'}</FeatureValue>

        <FeatureValue title="Тематика">{o?.subtype.name || '-'}</FeatureValue>

        <FeatureValue title="Адрес">
          {o?.rentArea?.rawAddress || '-'}
        </FeatureValue>

        <FeatureValue title="Автор">
          {o?.initiator.fullName || '-'}
        </FeatureValue>

        <FeatureValue title="Источник обращения" color="text-300">
          {o?.orderOrigin ? ORDER_ORIGINS[o.orderOrigin] : '-'}
        </FeatureValue>

        <FeatureValue title="Последние изменения" color="text-300">
          {parseDate(o?.changedDate, 'dd.MM.yyyy в HH:mm') || '-'}
        </FeatureValue>
      </Grid>

      <OrderStatusConfirmModal
        counter={counter}
        isOpen={confirmModal.isOpen}
        onClose={onModalClose}
        code={code}
        onChangeCode={setCode}
        phone={profile?.phone || ''}
        isPendingSendOtp={isPendingChangeStatus}
        isPendingSubmitOtp={isPendingConfirmStatus}
        onSendOtp={() => changeOrderStatus(newStatus)}
        onSubmitOtp={() => confirmChangeOrderStatus(code.join(''))}
      />
    </>
  )
}
