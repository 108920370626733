import { withStyles } from '@bruitt/classnames'

import { formatPrice } from '../../../../shared/lib/format-price'
import { Box } from '../../../../shared/uikit/box'
import { Card } from '../../../../shared/uikit/card'
import { DotList } from '../../../../shared/uikit/dot-list'
import { Flex } from '../../../../shared/uikit/flex'
import { SkeletonBox } from '../../../../shared/uikit/skeleton-box'
import { Text, TextProps } from '../../../../shared/uikit/text'
import { RENT_AREA_TYPES } from '../../constants'
import { useRentAreaPhotoQuery } from '../../hooks/use-rent-area-photo-query'
import { RentAreaListItem } from '../../types'

import s from './rent-area-card.module.scss'

const sx = withStyles(s)

interface RentAreaCardProps {
  rentArea: RentAreaListItem
}

const RentAreaCard = ({ rentArea: r }: RentAreaCardProps) => {
  const { photoUrl } = useRentAreaPhotoQuery({
    rentAreaId: r.rentAreaId,
    photoFileName: r.photo?.fileName || '',
  })

  return (
    <Card p="16" pb_lg="24">
      <Box px_md="12" px_lg="0">
        <Text s="caption1" color="text-300">
          {r.destinationType && RENT_AREA_TYPES[r.destinationType]} №{r.unit}
        </Text>

        <Text s="caption1" color="text-300" mt="12">
          Арендная доходность
        </Text>
        <Flex g="12" ai="center">
          <Text s="title3-bold" color="text-900">
            {r.profit ? `${formatPrice(r.profit)} ₽` : '-'}
          </Text>
          {r.profitPercentage && (
            <Label>+{Math.ceil(r.profitPercentage)}%</Label>
          )}
        </Flex>

        <Text s="caption1" color="text-300" mt="12">
          Оценочная стоимость
        </Text>
        <Flex g="12" ai="center">
          <Text s="body" color="text-900">
            {formatPrice(r.ownershipInfo.price)} ₽
          </Text>
          {r.ownershipInfo.priceDifferencePercentage && (
            <Label>
              +{Math.ceil(r.ownershipInfo.priceDifferencePercentage)}%
            </Label>
          )}
        </Flex>

        <Box mt="16">
          <DotList>
            {/* <DotList.Item>
              <Text s="caption1">-</Text>
            </DotList.Item> */}
            <DotList.Item>
              <Text s="caption1">
                этаж {r.floor} из {r.project.floorsCount || '-'}
              </Text>
            </DotList.Item>
            <DotList.Item>
              <Text s="caption1">{r.areaSize} м²</Text>
            </DotList.Item>
          </DotList>
        </Box>

        <Text s="caption1" mt="8">
          {r.project.name}
        </Text>
        <Text s="caption1" color="text-300">
          {r.rawAddress}
        </Text>
      </Box>

      <Box mt="24" className={s.imageWrapper} rad="16">
        {photoUrl && <img className={s.image} src={photoUrl} alt="" />}
      </Box>
    </Card>
  )
}

const Skeleton = () => {
  return (
    <Card p="16" pb_lg="24">
      <Box px_md="12" px_lg="0">
        <SkeletonBox h="16" />
        <SkeletonBox h="40" mt="12" />
        <SkeletonBox h="40" mt="12" />
        <SkeletonBox h="16" mt="16" />
        <SkeletonBox h="32" mt="8" />
      </Box>

      <Box mt="24" className={s.imageWrapper} rad="16"></Box>
    </Card>
  )
}

const Label = (props: TextProps) => (
  <Text
    className={sx(s.label)}
    s="footnote"
    color="green-500"
    bgColor="base-50"
    rad="10"
    {...props}
  />
)

const RentAreaCardNamespace = Object.assign(RentAreaCard, { Skeleton })

export { RentAreaCardNamespace as RentAreaCard }
