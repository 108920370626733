import { Flex } from '../flex'

import s from './dot-list.module.scss'

interface DotListProps {
  children?: React.ReactNode
}

const DotList = ({ children }: DotListProps) => {
  return (
    <Flex ai="center" g="12">
      {children}
    </Flex>
  )
}

interface ItemProps {
  children?: React.ReactNode
}

const Item = ({ children }: ItemProps) => {
  return <div className={s.item}>{children}</div>
}

const DotListNamespace = Object.assign(DotList, { Item })

export { DotListNamespace as DotList }
