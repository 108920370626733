import { withStyles } from '@bruitt/classnames'

import { Box, BoxProps } from '../box'

import s from './skeleton-box.module.scss'

const sx = withStyles(s)

interface SkeletonBoxProps extends BoxProps {
  h?: '16' | '20' | '32' | '40' | '44' | '52' | '56' | '124'
}

export const SkeletonBox = ({
  h = '44',
  className,
  ...rest
}: SkeletonBoxProps) => (
  <Box className={sx(s.block, { h }, className)} rad="8" {...rest} />
)
