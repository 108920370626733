import { getRouteApi } from '@tanstack/react-router'

import { ORDER_LIST_SORT_FIELDS } from '../../entities/order/constants'
import { useOrderTypesQuery } from '../../entities/order/hooks/use-order-types-query'
import { OrderSortColumn } from '../../entities/order/types'
import { useAllRentAreas } from '../../entities/rent-area/hooks/use-all-rent-areas'
import { useModal } from '../../shared/hooks/use-modal'
import { SortingRequestParams } from '../../shared/types/api-types'
import { SORT_DIRECTIONS } from '../../shared/types/sort'

import { OrdersFilterSearch, OrdersSearch } from './types'

const { useSearch, useNavigate } = getRouteApi('/_layout-main/orders/')

export const useOrdersPage = () => {
  // const { permissions } = useUser()
  const mobileFilterModal = useModal()
  const {
    sorting = ORDER_LIST_SORT_FIELDS.createdAt,
    sortingDir = SORT_DIRECTIONS.desc,
    ...filter
  } = useSearch()

  const navigate = useNavigate()
  const handleChangeSearchParams = (search: OrdersSearch) => {
    void navigate({
      search: (prev) => ({ ...prev, ...search }),
      replace: true,
    })
  }

  const { rentAreas, projects } = useAllRentAreas()
  const { orderTypes } = useOrderTypesQuery()

  const changeSort = (s: SortingRequestParams<OrderSortColumn>) =>
    handleChangeSearchParams({ sorting: s.columnName, sortingDir: s.direction })
  const handleChangeFilter = (filter: OrdersFilterSearch) => {
    handleChangeSearchParams(filter)
  }

  return {
    data: {
      filter,
      sorting,
      sortingDir,
      mobileFilterModal,
      rentAreas,
      projects,
      orderTypes,
    },
    actions: { changeSort, handleChangeFilter },
  }
}
