import { withStyles } from '@bruitt/classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { FileDrop } from 'react-file-drop'

import {
  DOCUMENT_FILE_EXTENSIONS,
  DOCUMENT_MIME_TYPES,
} from '../../constants/files'
import { BaseButton } from '../../uikit/base-button'
import { ErrorText } from '../../uikit/error-text'
import { FilePreview } from '../../uikit/file-preview'
import { Flex } from '../../uikit/flex'
import { Icon } from '../../uikit/icon'

import s from './files-upload.module.scss'

const sx = withStyles(s)

const MAX_FILE_SIZE_MB = 10
const BYTES_IN_MB = 1024 * 1024
const MAX_FILES_COUNT = 6

interface FilesUploadProps {
  children?: React.ReactNode
  files: File[]
  onChange?: (files: File[]) => void
  maxFilesCount?: number
  maxFileSizeMb?: number
  fileMimeTypes?: Set<string>
  fileExtensions?: string[]
}

export const FilesUpload = ({
  children,
  files,
  onChange,
  maxFilesCount = MAX_FILES_COUNT,
  maxFileSizeMb = MAX_FILE_SIZE_MB,
  fileMimeTypes = DOCUMENT_MIME_TYPES,
  fileExtensions = DOCUMENT_FILE_EXTENSIONS,
}: FilesUploadProps) => {
  const [isDndActive, setIsDndActive] = useState(false)
  const [fileErrors, setFileErrors] = useState<string[]>([])

  const inputFileRef = useRef<HTMLInputElement>(null)

  const filesSrc = useMemo(
    () => files.map((f) => URL.createObjectURL(f)),
    [files],
  )

  useEffect(() => {
    return () => {
      filesSrc.forEach((url) => URL.revokeObjectURL(url))
    }
  }, [filesSrc])

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0)
      handleUpload(e.target.files)
  }

  const openFileUploader = () => {
    inputFileRef.current?.click()
  }

  const removeFileFromFileList = (index: number) => {
    if (!inputFileRef.current) return

    const dt = new DataTransfer()
    const input = inputFileRef.current
    const files = input.files || []

    for (let i = 0; i < files.length; i++) {
      if (index !== i) {
        dt.items.add(files[i]!)
      }
    }

    inputFileRef.current.files = dt.files
  }

  const handleDeleteFile = (index: number) => {
    removeFileFromFileList(index)
    onChange?.(files.filter((_, i) => i !== index))
  }

  const handleUpload = (fileList: FileList) => {
    const validationErrors: string[] = []

    const validFiles = Array.from(fileList).filter((f) => {
      let isValid = true

      if (f.size > maxFileSizeMb * BYTES_IN_MB) {
        validationErrors.push(
          `${f.name}. Размер файла превышает ${maxFileSizeMb} Мб`,
        )
        isValid = false
      }

      if (fileMimeTypes.has(f.type) === false) {
        validationErrors.push(`${f.name}. Формат файла должен быть JPG и PNG`)
        isValid = false
      }

      return isValid
    })

    if (validationErrors.length > 0) {
      setFileErrors(validationErrors)
    }

    if (fileErrors.length > 0) setFileErrors([])

    if (validFiles.length > 0) {
      onChange?.([
        ...files,
        ...validFiles.slice(0, maxFilesCount - files.length),
      ])
    }

    if (inputFileRef.current) inputFileRef.current.value = ''
  }

  return (
    <div>
      <Flex cg="8" rg="12" w="wrap">
        {children}

        {filesSrc.map((url, i) => (
          <FilePreview
            key={url}
            url={url}
            onDelete={() => handleDeleteFile(i)}
            fileName={files[i]?.name}
            originalFileName={files[i]?.name}
          />
        ))}

        {files.length !== maxFilesCount && (
          <FileDrop
            onDragOver={() => {
              setIsDndActive(true)
            }}
            onDragLeave={() => {
              setIsDndActive(false)
            }}
            onDrop={(files) => {
              setIsDndActive(false)
              if (files?.length) handleUpload(files)
            }}
          >
            <BaseButton
              className={sx(s.button, { isDndActive })}
              onClick={openFileUploader}
            >
              <Icon name="attachment-2" s="24" />
            </BaseButton>
          </FileDrop>
        )}
      </Flex>

      {fileErrors.map((text, i) => (
        <ErrorText key={i} text={text} />
      ))}

      <input
        ref={inputFileRef}
        onChange={handleFileChange}
        multiple
        type="file"
        accept={fileExtensions.join(', ')}
        name=""
        hidden
      />
    </div>
  )
}
