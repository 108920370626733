import { forwardRef } from 'react'
import DatePicker, { CalendarProps } from 'react-multi-date-picker'

import { IconSimpleButton } from '../../uikit/icon-simple-button'
import { Input, InputProps } from '../../uikit/input'
import { InputWrapperProps } from '../../uikit/input-wrapper'

import styles from './input-date.module.scss'

interface InputDateProps
  extends Omit<
    InputWrapperProps,
    'autoComplete' | 'defaultValue' | 'onChange' | 'type' | 'value'
  > {
  value?: CalendarProps['value']
  onChange?: (value: string) => void
  minDate?: CalendarProps['minDate']
  maxDate?: CalendarProps['maxDate']
  s?: InputProps['s']
}

export const InputDate = forwardRef<HTMLInputElement, InputDateProps>(
  ({ placeholder, value, onChange, minDate, maxDate, s, ...rest }, _) => {
    const handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      onChange?.('')
    }

    return (
      <DatePicker
        value={value}
        onChange={(value) => {
          onChange?.(
            value
              ? new Date(
                  value.year,
                  value.month.number - 1,
                  value.day,
                ).toISOString()
              : '',
          )
        }}
        // @ts-ignore
        renderButton={(direction, handleClick) => (
          <IconSimpleButton
            s="24"
            icon={direction === 'right' ? 'arrow-right-s' : 'arrow-left-s'}
            onClick={handleClick}
          />
        )}
        onOpenPickNewDate={false}
        monthYearSeparator=" "
        minDate={minDate}
        maxDate={maxDate}
        locale={LOCALE}
        format="DD.MM.YYYY"
        showOtherDays
        weekStartDayIndex={1}
        shadow={false}
        headerOrder={['MONTH_YEAR', 'LEFT_BUTTON', 'RIGHT_BUTTON']}
        arrow={false}
        offsetY={4}
        portal
        containerClassName={styles.inputContainer}
        render={(value, openCalendar) => {
          return (
            <Input
              s={s}
              placeholder={placeholder}
              value={value}
              onChange={() => {}}
              onFocus={openCalendar}
              autoComplete="off"
              post={
                value ? (
                  <IconSimpleButton s="24" icon="close" onClick={handleClear} />
                ) : null
              }
              {...rest}
            />
          )
        }}
      />
    )
  },
)

InputDate.displayName = 'InputDate'

const LOCALE = {
  name: 'russian_ru',
  months: [
    ['Январь', 'Янв'],
    ['Февраль', 'Фев'],
    ['Март', 'Март'],
    ['Апрель', 'Апр'],
    ['Май', 'Май'],
    ['Июнь', 'Июнь'],
    ['Июль', 'Июль'],
    ['Август', 'Авг'],
    ['Сентябрь', 'Сент'],
    ['Октябрь', 'Окт'],
    ['Ноябрь', 'Ноя'],
    ['Декабрь', 'Дек'],
  ],
  weekDays: [
    ['Сб', 'Сб'],
    ['Вс', 'Вс'],
    ['Пн', 'Пн'],
    ['Вт', 'Вт'],
    ['Ср', 'Ср'],
    ['Чт', 'Чт'],
    ['Пт', 'Пт'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['', ''],
    ['', ''],
  ],
}
