import { useQuery } from '@tanstack/react-query'

import { getRentAreaPhoto, GetRentAreaPhotoRequest } from '../api'
import { RENT_AREA_PHOTO_QUERY_KEY } from '../constants'

export const useRentAreaPhotoQuery = (
  params: Partial<GetRentAreaPhotoRequest>,
) => {
  const { rentAreaId, photoFileName } = params

  const { data, ...rest } = useQuery({
    queryKey: [RENT_AREA_PHOTO_QUERY_KEY, rentAreaId, photoFileName],
    queryFn: () =>
      getRentAreaPhoto({
        photoFileName: photoFileName!,
        rentAreaId: rentAreaId!,
      }),
    enabled: Boolean(rentAreaId && photoFileName),
  })

  return { photoUrl: data, ...rest }
}
