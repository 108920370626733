import { useQuery } from '@tanstack/react-query'
import * as R from 'rambda'
import { useMemo } from 'react'

import { SORT_DIRECTIONS } from '../../../shared/types/sort'
import { getRentAreas } from '../api'
import { ALL_RENT_AREAS_QUERY_KEY, RENT_AREA_SORT_FIELDS } from '../constants'
import { RentAreaListItem } from '../types'

interface UseAllRentAreasParams {
  enabled?: boolean
}

export const useAllRentAreas = ({
  enabled = true,
}: UseAllRentAreasParams = {}) => {
  const { data, ...rest } = useQuery({
    queryKey: [ALL_RENT_AREAS_QUERY_KEY],
    queryFn: () =>
      getRentAreas({
        pagination: { page: 1, countPerPage: 10_000 },
        sorting: {
          columnName: RENT_AREA_SORT_FIELDS.type,
          direction: SORT_DIRECTIONS.asc,
        },
      }),

    enabled,
  })

  const projects: RentAreaListItem['project'][] = useMemo(
    () =>
      R.uniqBy(
        R.prop('id'),
        R.map((ra) => ra.project, data?.list || []),
      ),
    [data?.list],
  )

  return {
    rentAreas: data?.list || [],
    projects,
    ...rest,
  }
}
