import * as R from 'rambda'
import { useEffect, useMemo, useState } from 'react'

import { FileDetails } from '../../../../shared/types/api-types'
import { OrderFieldValue } from '../../types'
import {
  CustomFieldsFilesValues,
  CustomFieldsFormValues,
  OrderRolePermission,
  OrderSettingsField,
} from '../../types-schema'
import { CUSTOM_FIELDS_UTILS } from '../../utils/custom-field-utils'

type ErrorState = { [key: string]: string }

interface CustomFieldsFormParams {
  initialValues?: OrderFieldValue[]
  initialFiles?: OrderFieldValue<FileDetails>[]
  fields?: OrderSettingsField[]
  permissions?: OrderRolePermission
  onSubmit?: (
    values: CustomFieldsFormValues,
    files: CustomFieldsFilesValues,
  ) => void
}

export const useCustomFieldsForm = ({
  initialValues,
  initialFiles,
  fields = [],
  permissions = {
    visible: [],
    canEdit: [],
    required: [],
  },
  onSubmit,
}: CustomFieldsFormParams) => {
  const fieldsMap = R.indexBy(R.prop('id'), fields)

  const initialCustomFieldsFormValues = useMemo(() => {
    return (initialValues || []).reduce<CustomFieldsFormValues>(
      (res, { fieldId, values }) => {
        res[fieldId] = values
        return res
      },
      {},
    )
  }, [initialValues])

  const remoteFiles = useMemo(() => {
    return (initialFiles || []).reduce<CustomFieldsFormValues<FileDetails>>(
      (res, { fieldId, values }) => {
        res[fieldId] = values
        return res
      },
      {},
    )
  }, [initialFiles])

  useEffect(() => {
    setValues(initialCustomFieldsFormValues)
  }, [initialCustomFieldsFormValues])

  const [values, setValues] = useState<CustomFieldsFormValues>({})
  const [files, setFiles] = useState<CustomFieldsFilesValues>({})
  const [errors, setErrors] = useState<ErrorState>({})

  const isDirty = useMemo(
    () =>
      R.equals(values, initialCustomFieldsFormValues) === false ||
      Object.values(files).some((files) => (files.length || 0) > 0),
    [values, initialCustomFieldsFormValues, files],
  )

  const handleChangeValue = (id: number) => (value: string[]) => {
    setValues((p) => ({ ...p, [id]: value }))
  }

  const handleChangeFiles = (id: number) => (value: File[]) => {
    setFiles((p) => ({ ...p, [id]: value }))
  }

  const handleSubmit = () => {
    setErrors({})
    const requiredFields = new Set(permissions.required)

    const newErrors = fields.reduce<ErrorState>((res, { id }) => {
      if (requiredFields.has(id) && !values[id]) {
        res[id] = 'Обязательное поле'
      }

      return res
    }, {})

    Object.entries(values).forEach(([fieldId, value]) => {
      const field = fieldsMap[parseInt(fieldId, 10)]!

      const fieldUtils = CUSTOM_FIELDS_UTILS[field.type]

      if (value && fieldUtils?.isValid) {
        const validation = fieldUtils.isValid(value)

        if (validation !== true && !newErrors[fieldId]) {
          newErrors[fieldId] = validation
        }
      }
    })

    if (Object.values(newErrors).length > 0) {
      setErrors(newErrors)
    } else {
      onSubmit?.(values, files)
    }
  }

  return {
    isDirty,
    values,
    files,
    remoteFiles,
    handleChangeValue,
    handleChangeFiles,
    errors,
    handleReset: () => setValues(initialCustomFieldsFormValues),
    handleSubmit,
    onSuccess: () => setFiles({}),
  }
}
