import { useQueryClient } from '@tanstack/react-query'

import { ORDER_LIST_QUERY_KEY } from '../../entities/order/constants'
import { ROUTES } from '../../shared/constants/routes'

import { MenuItem } from './types'

export const useMenuItems = (): MenuItem[] => {
  const qc = useQueryClient()

  const refetchOrders = () =>
    qc.invalidateQueries({ queryKey: [ORDER_LIST_QUERY_KEY] })

  return [
    // {
    //   title: 'Главная',
    //   icon: 'home-6',
    //   to: ROUTES.index,
    // },
    {
      title: 'Помещения',
      icon: 'rounded-corner',
      to: ROUTES.rentAreas,
    },
    {
      title: 'Заявки',
      icon: 'message-2',
      to: ROUTES.orders,
      onClick: refetchOrders,
    },
  ]
}
